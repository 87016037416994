@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,500,600,700);
@import url(https://fonts.googleapis.com/css?family=Cardo:400,400italic,700);
/* ==========================================================================
Typography
========================================================================== */
p {
  font-size: 14px;
  line-height: 22.4px;
  color: #6c7279; }

  .pred {
    color: red;
  }

  .pgreen {
    color: green;
  }

h1 {
  font-size: 65px;
  color: #2d3033; }

h2 {
  font-size: 40px;
  color: #9eac5f; }

h3 {
  font-size: 28px;
  color: #889658;
  font-weight: 300; }
  .derecha { float: right}

h4 {
  font-size: 22px;
  color: #2d3033;
  font-weight: 400; }
  .derecha { float: right}

h5 {
  font-size: 14px;
  color: #2d3033;
  text-transform: uppercase;
  font-weight: 700; }
  .derecha { float: right}

.bton {
  background-color: #6d6d6d;
  padding: 5px;
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-weight: 600;
  border: 0;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 10px;
  display: inline-block;
  text-transform: uppercase;
  margin-left: 3px;
  margin-right: 3px;
  margin-top: 3px;
  margin-bottom: 3px;
  text-align: center;
  max-width: 40rem; }
  .bton:hover, .bton:focus {
    text-decoration: none;
    background-color: #000000;
    color: rgb(250, 250, 250);
   }
  .bton-large {
    padding: 10px 20px }
  .bton-carrpagar {
    background-color: #0bac49;
    color: rgb(255, 255, 255);
    font-size: 16px;
    font-weight: 600;
    border: 0;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 12px;
    display: inline-block;
    text-transform: uppercase;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
    max-width: 40rem; 
  }
.bton-acord {
  background: none;
  border: none;
}
.hola-salir {
  background:none;
  border:none;
  display: inline;
  text-transform: capitalize;
  font-size: 12px;
  margin: 0 0 3px 0;
  padding:0 0 1px 0;
}
.bton-salir {
  background:none;
  border:none;
  display: inline;
  text-transform: capitalize;
  font-size: 12px;
  margin: 0 0 3px 0;
  padding:0 0 1px 0;
  cursor: pointer;
} 
.bton-salir:hover {
color: black;
}
.mi-cuenta {
  font-size: 12px;
  text-transform: capitalize;
  margin-left: 4px;
  padding-left: 4px;
  color:rgb(237, 201, 252);
 text-align: right;
}


/* ==========================================================================
	General Styles
========================================================================== */
a {
  color: #000000; }

a:hover, a:focus {
  text-decoration: none;
  -moz-transition: background-color, color, 0.3s;
  -o-transition: background-color, color, 0.3s;
  -webkit-transition: background-color, color, 0.3s;
  transition: background-color, color, 0.3s; }

body {
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  color: #6c7279; }

ul, ol {
  margin: 0;
  padding: 0; }

ul li {
  list-style: none; }

.section {
  padding: 80px 0; }

.no-padding {
  padding: 0; }

.no-gutter [class*=col-] {
  padding-right: 0;
  padding-left: 0; }

/* ==========================================================================
	$Header
========================================================================== */
#header {
  background-color: rgb(190, 51, 255);
  position:relative; 
  width: 100%;
  z-index: 999; }
  #header .header-content {
    border-bottom: 1px solid rgb(39, 39, 39);
    margin: 0 auto;
    padding: 5px 5px 0 0;
    width: 100%;
    margin-right: 3px;
    -moz-transition: padding 0.3s;
    -o-transition: padding 0.3s;
    -webkit-transition: padding 0.3s;
    transition: padding 0.3s; }

  #header .logo {
    float: left, top; 
    font-family: 'American Typewriter', sans-serif;
    font-weight: 400;
    color: rgba(49, 49, 49, 0.75);
    font-size: calc(11px + 1.5vw);
    max-width: 60px;
    max-height: 30px;
  text-decoration: none; }

#header.fixed {
  background-color: #6e4d4d; }
  #header.fixed .header-content {
    border-bottom: 0;
    padding: 12px 0 0 0;
  margin-right: 5px; }
  #header.fixed .nav-toggle {
    top: 0px; }

.navigation.open {
  opacity: 0.9;
  visibility: visible;
  -moz-transition: opacity 0.5s;
  -o-transition: opacity 0.5s;
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s; }
 
.navigation {
  float: right; }
  .navigation li {
    display: inline-block; }
  .navigation a {
    color: rgba(248, 248, 248, 0.75);
    font-size: 16px;
    font-weight: 700;
    margin-left: 20px;
    text-transform: uppercase; }
    .navigation a:hover, .navigation a.active {
      color: rgb(66, 35, 95);
    text-decoration: none; }


.nav-toggle {
  border:none;
  background: none;
  display: none;
  height: 44px;
  overflow: hidden;
  position: fixed;
  right: 5%;
  text-indent: 100%;
  top: 16px;
  white-space: nowrap;
  width: 44px;
  z-index: 99999;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }
  .nav-toggle:before, .nav-toggle:after {
    border-radius: 50%;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -moz-backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -moz-transition-property: -moz-transform;
    -o-transition-property: -o-transform;
    -webkit-transition-property: -webkit-transform;
    transition-property: transform; }
  .nav-toggle:before {
    background-color: #1b1b1b;
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
    -moz-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s; }
  .nav-toggle:after {
    background-color: #000000;
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -webkit-transform: scale(0);
    transform: scale(0);
    -moz-transition-duration: 0s;
    -o-transition-duration: 0s;
    -webkit-transition-duration: 0s;
    transition-duration: 0s; }
  .nav-toggle span {
    background-color: rgb(255, 255, 255);
    bottom: auto;
    display: inline-block;
    height: 3px;
    left: 50%;
    position: absolute;
    right: auto;
    top: 50%;
    width: 18px;
    z-index: 10;
    -moz-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%); }
    .nav-toggle span:before, .nav-toggle span:after {
      background-color: rgb(255, 255, 255);
      content: "";
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
      -moz-transform: translateZ(0);
      -ms-transform: translateZ(0);
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
      -moz-backface-visibility: hidden;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      -moz-transition: -moz-transform 0.3s;
      -o-transition: -o-transform 0.3s;
      -webkit-transition: -webkit-transform 0.3s;
      transition: transform 0.3s; }
    .nav-toggle span:before {
      -moz-transform: translateY(-6px) rotate(0deg);
      -ms-transform: translateY(-6px) rotate(0deg);
      -webkit-transform: translateY(-6px) rotate(0deg);
      transform: translateY(-6px) rotate(0deg); }
    .nav-toggle span:after {
      -moz-transform: translateY(6px) rotate(0deg);
      -ms-transform: translateY(6px) rotate(0deg);
      -webkit-transform: translateY(6px) rotate(0deg);
      transform: translateY(6px) rotate(0deg); }
  .nav-toggle.close-nav:before {
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -webkit-transform: scale(0);
    transform: scale(0); }
  .nav-toggle.close-nav:after {
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1); }
  .nav-toggle.close-nav span {
    background-color: rgba(255, 255, 255, 0); }
    .nav-toggle.close-nav span:before, .nav-toggle.close-nav span:after {
      background-color: rgb(255, 255, 255); }
    .nav-toggle.close-nav span:before {
      -moz-transform: translateY(0) rotate(45deg);
      -ms-transform: translateY(0) rotate(45deg);
      -webkit-transform: translateY(0) rotate(45deg);
      transform: translateY(0) rotate(45deg); }
    .nav-toggle.close-nav span:after {
      -moz-transform: translateY(0) rotate(-45deg);
      -ms-transform: translateY(0) rotate(-45deg);
      -webkit-transform: translateY(0) rotate(-45deg);
      transform: translateY(0) rotate(-45deg); }


/* ==========================================================================
	$Hero
========================================================================== */

      .link-sin-style {
        text-decoration: none;
      }

      .hero {
        background: linear-gradient(rgba(0, 0, 0, 0.5), transparent), url(../assets/hero-backg.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        min-height: 43vh;
        color: rgb(237, 201, 252);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
      }
      
      .hero h3 {
        padding: 3rem;
      }
      
      .hero .wrapper {
        width: 90%;
        margin: auto;
      }
      
      /* Featured Books and Books Section */
      
      .featured-head {
        background: #8d23f0;
        padding: 0rem;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      
      .books {
        display: flex;
        flex-wrap: wrap;
        justify-content:center;
        text-align: center;
        width: 90%;
        margin: auto;
        min-width: 30rem;
      }
      
      .book {
        display: flex;
        flex-direction: column; 
        justify-content: space-between;
/*        align-items: center; */
        box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1), 0px 20px 20px rgba(0, 0, 0, 0.1);
        max-width: 40rem;
        max-height: 40rem;
        margin: 3rem 4rem;
        border-top: 1px solid #efefef;
        background: floralwhite;
        border-radius: 3%;
        min-width: 30rem;
      }
      
      .featured-list {
        justify-content: space-evenly;
      }
      
      .featured-book {
        margin: 1rem 0;
      }
      
      .book-image {
        flex: 2 1 10rem;
        padding: 0rem 0rem;
        margin-top: 2rem;
        margin-left: 2rem;
        border-radius: 35%;
        float: left;
      }
      
      .book-link {
        flex: 1 1 100rem;
        background: #55606c;
        font-size: 1.7rem;
        width: 100%;
        height: 100%;
      }
      
      .book-link:hover {
        background: #343c43;
      }
      
      .book img {
        min-height: 10vh;
        height: 21vh;
        min-width: 15vh;
      }
      
      /* Book Details Section */
      
      .book-details {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        width: 90%;
        margin: auto;
        height: 80vh;
        text-align: center
      }
      
      .detail-image {
        text-align: center;
      }
      
      .detail-description {
        display:grid;
        flex-wrap: wrap;
        flex: 1 1 1rem;
        margin: 0;
        flex-direction: column;
        align-items:center;
        justify-content:space-evenly;
        height: 75%;
        padding: 0 0rem;
      }

      .datospers {
        display:grid;
        margin: 0;
        flex-direction:column;
        text-align: right;
        align-items: baseline; 
        justify-content:space-between;
        padding: 0 0rem;
      }

      .datosenvio {
        display:grid;
        margin: 0;
        flex-direction:column;
        text-align: right;
        align-items: baseline; 
        justify-content:space-between;
        padding: 0 0rem;
      }
      .errorform {
        color: red;
        font-size: 12px;
      }
      .border-error {
        border: 2px solid red;
      }
      .bton-actdatos {
        display:flex;
        margin: 0;
        flex-direction:row;
        text-align: right;
        align-items: center; 
        justify-content:space-between;
        padding: 0 2rem;
      }

/* ==========================================================================
	$Tarjeta
========================================================================== */

.tarjeta {
display: flex;
}

.tarjeta-imgbtntxt {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0 0;
  margin: 0;
}
.tarjeta-btntxt {
  display: flex;
  flex-direction: column;
  float: left;
  text-align: left;
  margin-left: 1rem;
}
.tarjeta-featured {
  display: flex;
  flex-direction: column;
  float: center;
  text-align: center;
  margin-left: 0rem;
}
.tarjeta-imagen-cent {
  justify-content: center;
  border-radius: 55px;
  flex: 2 1 10rem;
  padding: 0rem 0rem;
  margin-top: 1rem;
  margin-left: 1rem;
  border-radius: 35%;
  float: center;
}

.tarjeta-bton {
  display: flex;
  margin-top: 2rem;
  margin-right: 1rem;
  float: right;
}
.tarjeta-texto {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.tarjeta-texto-linea {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 1rem;
  margin-left: 1rem;
}

/* ==========================================================================
	$FormAdmin agregar prods
========================================================================== */


.form-wrapper {
  display: flex;
  flex-wrap:wrap;
  align-items: top;
  justify-content: center;
  border: 1px #c7c7c7 solid;
  padding: 1rem;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1), 0px 20px 20px rgba(0, 0, 0, 0.1);
  margin: 1rem 1rem;
  border-top: 1px solid #efefef;
  background: floralwhite;
  border-radius: 3%;
}

.form-image {
  flex: 1 1 50rem;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.centertext {
  width: auto;
  margin: 0 auto;
  text-align: center;
}

.image-preview {
  width: 35rem;
  margin-bottom: 1rem;
}

.form-fields {
  flex: 3 1 50rem;
  text-align: center;
}

.form-fields input {
  width: 100%;
  height: 4rem;
  font-size: 2rem;
}

.form-fields textarea {
  width: 100%;
  font-size: 2rem;
}

.form-wrapper label {
  margin: 0rem 2rem;
  text-align: left;
}

.title-form,
.description-form,
.author-form,
.price-form,
.submit-form,
.featured-form {
  padding: 0.7rem;
}

.form-fields .featured-checkbox {
  width: 2rem;
  position: relative;
  top: 1.4rem;
}

.submit-form {
  width: 100%;
}

.StripeElement {
  background-color: white;
  padding: 8px 12px;
  border-radius: 6px;
  text-align: end;
  border: 3px solid transparent;
  box-shadow: 0 6px 10px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
  }

  .StripeElement--focus {
    box-shadow: 0 1px 3px 0 #cfd7df;
    }
    
    .StripeElement--invalid {
    border-color: #fa755a;
    }
    
    .StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
    }
.stripe-section {
  padding: 10px;
  text-align: center;
}

.card-element {
margin: 2rem 2rem;
  text-align: end;

}


/* ==========================================================================
	$Banner
========================================================================== */
.banner-carrito-flotante {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    width: 100%;
    background: rgba(0,0,0,.75);
    z-index: 9999;
    padding: 5px 0;
}
.text-banner-blanco {
  color: whitesmoke;
  font-size: 20px;
}




.banner {
  background-color: rgb(229, 186, 253);
  background-position: center top;
  -moz-background-size: cover;
  -o-background-size: cover;
  -webkit-background-size: cover;
  background-size: cover;
  min-height: 50px; }
  
 .banner-text {
  padding-top: 5%;
  vertical-align: middle;
   }
  .banner-text h1 {
    color: rgb(255, 255, 255);
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    text-transform: uppercase; }
  .banner-text p {
    color: rgb(4, 0, 255);
    font-size: 32px;
    font-weight: 800;
    line-height: 1.5;
    margin-bottom: 30px; }
    .banner-text h3 {
      font-size: 28px;
      color: rgb(176, 206, 6);
      font-family: "Open Sans", sans-serif;
      font-weight: 700;
}    

/* ==========================================================================
	$Features
========================================================================== */
.fondo {
  background-image: url(../assets/hero-backg.jpg);
  opacity: 70%;
  background-size:cover; 
  background-position: center top;
  background-repeat: no-repeat;
  padding-bottom: 5%;
  max-height: 20%;

}

.feature {
  margin-bottom: 10px; }
  .feature-content {
    padding: 0 15px;
    margin-top: 30px; }
  .feature .icon {
    color: #e84545;
    font-size: 60px; }

/* ==========================================================================
	$Works
========================================================================== */
.work {
  -moz-box-shadow: 0 0 0 1px #fff;
  -webkit-box-shadow: 0 0 0 1px #fff;
  box-shadow: 0 0 0 1px #fff;
  min-height: 350px;
  overflow: hidden;
  position: relative;
  visibility: hidden; }
  .work .overlay {
    background: rgba(232, 69, 69, 0.9);
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
    -moz-transition: opacity, 0.3s;
    -o-transition: opacity, 0.3s;
    -webkit-transition: opacity, 0.3s;
    transition: opacity, 0.3s; }
  .work .overlay-caption {
    position: absolute;
    text-align: center;
    top: 50%;
    width: 100%;
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%); }
  .work h5, .work p, .work img {
    -moz-transition: all, 0.5s;
    -o-transition: all, 0.5s;
    -webkit-transition: all, 0.5s;
    transition: all, 0.5s; }
  .work h5, .work p {
    color: #fff;
    margin: 0;
    opacity: 0; }
  .work h5 {
    margin-bottom: 5px;
    -moz-transform: translate3d(0, -200%, 0);
    -ms-transform: translate3d(0, -200%, 0);
    -webkit-transform: translate3d(0, -200%, 0);
    transform: translate3d(0, -200%, 0); }
  .work p {
    -moz-transform: translate3d(0, 200%, 0);
    -ms-transform: translate3d(0, 200%, 0);
    -webkit-transform: translate3d(0, 200%, 0);
    transform: translate3d(0, 200%, 0); }

.work-box:hover img {
  -moz-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  transform: scale(1.2); }
.work-box:hover .overlay {
  opacity: 1; }
  .work-box:hover .overlay h5, .work-box:hover .overlay p {
    opacity: 1;
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }

/* ==========================================================================
	$Teams
========================================================================== */
.person {
  max-width: 270px; }
  .person-content {
    margin-top: 20px; }
  .person h4 {
    font-weight: 400; }
  .person h5 {
    color: #e84545;
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 20px; }

.social-icons, .footer .footer-share {
  margin-top: 30px; }
  .social-icons li, .footer .footer-share li {
    float: left; }
  .social-icons a, .footer .footer-share a {
    border: 1px solid #e8ecee;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    color: #c6cacc;
    display: block;
    font-size: 18px;
    height: 40px;
    line-height: 40px;
    margin-right: 5px;
    text-align: center;
    width: 40px; }
    .social-icons a:hover, .footer .footer-share a:hover {
      background-color: #5f5f5f;
      border-color: #5f5f5f;
      color: #fff; }

/* ==========================================================================
	$Testimonials
========================================================================== */
.testimonials {
  background-color: #adadad;
  position: relative; }
  .testimonials blockquote {
    border: 0;
    margin: 0;
    padding: 10px 10% 0; }
  .testimonials p {
    color: #fff;
    font: italic 36px "Cardo", Georgia, "Times New Roman", serif; }
  .testimonials cite {
    color: #fff;
    display: block;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    margin-top: 10px;
    text-transform: uppercase; }

/* ==========================================================================
	$Download
========================================================================== */
.download h3 {
  margin-top: 0; }
.download p {
  color: #2d3033;
  font-weight: 600;
  text-transform: uppercase; }
.download .btn {
  margin-top: 30px; }

/* ==========================================================================
	$Footer
========================================================================== */
.footer {
  text-align: center; }
  .footer-top {
    background-color: #7f0fff;
    padding-top: 50px; }
  .footer-bottom {
    background-color: #000000;
    padding: 20px 0; }
  .footer .footer-col {
    margin-bottom: 80px; }
  .footer h5 {
    color: #fff; }
  .footer h5 {
    margin-bottom: 20px; }
  .footer p {
    color: rgba(255, 255, 255, 0.5); }
  .footer a {
    color: #fff; }
    .footer a:hover {
      color: #000000; }
  .footer .footer-share {
    margin-top: 0; }
    .footer .footer-share li {
      display: inline-block;
      float: none; }
    .footer .footer-share a {
      border-width: 2px;
      color: #fff; }
  .footer .fa-heart {
    color: #e84545;
    font-size: 11px;
    margin: 0 2px; }

/* ==========================================================================
	$Utilities
========================================================================== */
.work:nth-child(1) {
  -moz-animation-delay: 0.1s;
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s; }
.work:nth-child(2) {
  -moz-animation-delay: 0.2s;
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s; }
.work:nth-child(3) {
  -moz-animation-delay: 0.3s;
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s; }
.work:nth-child(4) {
  -moz-animation-delay: 0.4s;
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s; }
.work:nth-child(5) {
  -moz-animation-delay: 0.8s;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s; }
.work:nth-child(6) {
  -moz-animation-delay: 0.7s;
  -webkit-animation-delay: 0.7s;
  animation-delay: 0.7s; }
.work:nth-child(7) {
  -moz-animation-delay: 0.6s;
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s; }
.work:nth-child(8) {
  -moz-animation-delay: 0.5s;
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s; }

.animated {
  visibility: visible; }

/* ==========================================================================
	Font Icon (http://www.elegantthemes.com/blog/freebie-of-the-week/free-line-style-icons)
========================================================================== */
@font-face {
  font-family: "elegant-theme-line";
  src: url('../fonts/elegant-theme-line.ttf?1439850014') format('truetype'), url('../fonts/elegant-theme-line.eot?1439850014') format('embedded-opentype'), url('../fonts/elegant-theme-line.svg?1439850014') format('svg'), url('../fonts/elegant-theme-line.woff?1439850014') format('woff'); }
.icon {
  font-family: 'elegant-theme-line';
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-mobile:before {
  content: "\e000"; }

.icon-laptop:before {
  content: "\e001"; }

.icon-desktop:before {
  content: "\e002"; }

.icon-tablet:before {
  content: "\e003"; }

.icon-phone:before {
  content: "\e004"; }

.icon-document:before {
  content: "\e005"; }

.icon-documents:before {
  content: "\e006"; }

.icon-search:before {
  content: "\e007"; }

.icon-clipboard:before {
  content: "\e008"; }

.icon-newspaper:before {
  content: "\e009"; }

.icon-notebook:before {
  content: "\e00a"; }

.icon-book-open:before {
  content: "\e00b"; }

.icon-browser:before {
  content: "\e00c"; }

.icon-calendar:before {
  content: "\e00d"; }

.icon-presentation:before {
  content: "\e00e"; }

.icon-picture:before {
  content: "\e00f"; }

.icon-pictures:before {
  content: "\e010"; }

.icon-video:before {
  content: "\e011"; }

.icon-camera:before {
  content: "\e012"; }

.icon-printer:before {
  content: "\e013"; }

.icon-toolbox:before {
  content: "\e014"; }

.icon-briefcase:before {
  content: "\e015"; }

.icon-wallet:before {
  content: "\e016"; }

.icon-gift:before {
  content: "\e017"; }

.icon-bargraph:before {
  content: "\e018"; }

.icon-grid:before {
  content: "\e019"; }

.icon-expand:before {
  content: "\e01a"; }

.icon-focus:before {
  content: "\e01b"; }

.icon-edit:before {
  content: "\e01c"; }

.icon-adjustments:before {
  content: "\e01d"; }

.icon-ribbon:before {
  content: "\e01e"; }

.icon-hourglass:before {
  content: "\e01f"; }

.icon-lock:before {
  content: "\e020"; }

.icon-megaphone:before {
  content: "\e021"; }

.icon-shield:before {
  content: "\e022"; }

.icon-trophy:before {
  content: "\e023"; }

.icon-flag:before {
  content: "\e024"; }

.icon-map:before {
  content: "\e025"; }

.icon-puzzle:before {
  content: "\e026"; }

.icon-basket:before {
  content: "\e027"; }

.icon-envelope:before {
  content: "\e028"; }

.icon-streetsign:before {
  content: "\e029"; }

.icon-telescope:before {
  content: "\e02a"; }

.icon-gears:before {
  content: "\e02b"; }

.icon-key:before {
  content: "\e02c"; }

.icon-paperclip:before {
  content: "\e02d"; }

.icon-attachment:before {
  content: "\e02e"; }

.icon-pricetags:before {
  content: "\e02f"; }

.icon-lightbulb:before {
  content: "\e030"; }

.icon-layers:before {
  content: "\e031"; }

.icon-pencil:before {
  content: "\e032"; }

.icon-tools:before {
  content: "\e033"; }

.icon-tools-2:before {
  content: "\e034"; }

.icon-scissors:before {
  content: "\e035"; }

.icon-paintbrush:before {
  content: "\e036"; }

.icon-magnifying-glass:before {
  content: "\e037"; }

.icon-circle-compass:before {
  content: "\e038"; }

.icon-linegraph:before {
  content: "\e039"; }

.icon-mic:before {
  content: "\e03a"; }

.icon-strategy:before {
  content: "\e03b"; }

.icon-beaker:before {
  content: "\e03c"; }

.icon-caution:before {
  content: "\e03d"; }

.icon-recycle:before {
  content: "\e03e"; }

.icon-anchor:before {
  content: "\e03f"; }

.icon-profile-male:before {
  content: "\e040"; }

.icon-profile-female:before {
  content: "\e041"; }

.icon-bike:before {
  content: "\e042"; }

.icon-wine:before {
  content: "\e043"; }

.icon-hotairballoon:before {
  content: "\e044"; }

.icon-globe:before {
  content: "\e045"; }

.icon-genius:before {
  content: "\e046"; }

.icon-map-pin:before {
  content: "\e047"; }

.icon-dial:before {
  content: "\e048"; }

.icon-chat:before {
  content: "\e049"; }

.icon-heart:before {
  content: "\e04a"; }

.icon-cloud:before {
  content: "\e04b"; }

.icon-upload:before {
  content: "\e04c"; }

.icon-download:before {
  content: "\e04d"; }

.icon-target:before {
  content: "\e04e"; }

.icon-hazardous:before {
  content: "\e04f"; }

.icon-piechart:before {
  content: "\e050"; }

.icon-speedometer:before {
  content: "\e051"; }

.icon-global:before {
  content: "\e052"; }

.icon-compass:before {
  content: "\e053"; }

.icon-lifesaver:before {
  content: "\e054"; }

.icon-clock:before {
  content: "\e055"; }

.icon-aperture:before {
  content: "\e056"; }

.icon-quote:before {
  content: "\e057"; }

.icon-scope:before {
  content: "\e058"; }

.icon-alarmclock:before {
  content: "\e059"; }

.icon-refresh:before {
  content: "\e05a"; }

.icon-happy:before {
  content: "\e05b"; }

.icon-sad:before {
  content: "\e05c"; }

.icon-facebook:before {
  content: "\e05d"; }

.icon-twitter:before {
  content: "\e05e"; }

.icon-googleplus:before {
  content: "\e05f"; }

.icon-rss:before {
  content: "\e060"; }

.icon-tumblr:before {
  content: "\e061"; }

.icon-linkedin:before {
  content: "\e062"; }

.icon-dribbble:before {
  content: "\e063"; }

  :root {
    /*------------------------------------------------------------
     |
     | 1.1 COLOR
     |
     ------------------------------------------------------------*/
   
     --ui-color-brand: #353535;
   
     /* COLOR PALETTE */
   
     --ui-color-n-000: #fff;
     --ui-color-n-050: #f5f5f5;
     --ui-color-n-100: #ebebeb;
     --ui-color-n-300: #aeaeae;
     --ui-color-n-500: #353535;
     --ui-color-n-700: #282828;
     --ui-color-n-900: #1a1a1a;
   
     /* BACKGROUND COLOR */
     
     --ui-color-background-primary  : var(--ui-color-n-000);
     --ui-color-background-secondary: var(--ui-color-n-050);
     --ui-color-background-tertiary : var(--ui-color-n-100);
   
     /* BORDER COLOR */
   
     --ui-color-border: var(--ui-color-n-100);
   
     /* TYPOGRAPHY COLOR */
   
     --ui-color-typography-heading: var(--ui-color-n-500);
     --ui-color-typography-body   : var(--ui-color-n-900);
     --ui-color-typography-note   : var(--ui-color-n-300);
     --ui-color-typography-button : var(--ui-color-n-000);
   
     /*------------------------------------------------------------
     |
     | 1.2 TYPOGRAPHY
     |
     ------------------------------------------------------------*/
   
     --ui-typography-typeface: "Inter", sans-serif;
   
     /* FONT SIZE */
   
     --ui-typography-h1: 1.9375rem;
     --ui-typography-h2: 1.5625rem;
     --ui-typography-h3: 1.25rem;
     --ui-typography-h4: 1rem;
     --ui-typography-p : 1rem;
     --ui-typography-s : .8125rem;
   
     /* LEADING */
   
     --ui-typography-h1-leading: 1.2;
     --ui-typography-h2-leading: 1.2;
     --ui-typography-h4-leading: 1.25;
     --ui-typography-p-leading : 1.5;
   
     /* MARGIN */
   
     --ui-typography-margin-heading: .75rem;
     --ui-typography-margin-body   : 1.125rem;
   
     /*------------------------------------------------------------
     |
     | 1.3 LAYOUT
     |
     ------------------------------------------------------------*/
     
     --ui-layout-container: 1.25rem;
     --ui-layout-grid     : 3.625rem;
     --ui-layout-gutter   : 1rem;
   
     /* GAP */
     
     --ui-gap-header  : 1rem;
     --ui-gap-cta     : .75rem;
     --ui-gap-hero    : 2rem;
     --ui-gap-customer: 2rem;
     --ui-gap-card    : 1.25rem;
     --ui-gap-pricing : 2rem;
     --ui-gap-faq     : 1.5rem;
   
     /* BORDER RADIUS */
     
     --ui-radius-avatar: 5rem;
     --ui-radius-button: 5rem;
     --ui-radius-card  : .5rem;
   
   }

    /*------------------------------------------------------------
   TYPOGRAPHY
   ------------------------------------------------------------*/
 
   .ui-text-intro { font-size: var(--ui-typography-h4); }
 
 

   /*--------------------------------------------------------------
 5.0 LAYOUT
 --------------------------------------------------------------*/
 
 .ui-layout-container {
   padding-left: var(--ui-layout-container);
   padding-right: var(--ui-layout-container);
   padding-top: 0.5rem;
  }

 
 .ui-layout-flex,
 .ui-layout-grid {
   align-items: center;
   justify-content: center;
 }
 
 .ui-layout-flex { display: flex; }
 
 .ui-layout-grid { display: grid; }
 
 /*--------------------------------------------------------------
 |
 | 6.2 BUTTON
 |
 --------------------------------------------------------------*/
 
 .ui-component-button {
   border: .0625rem solid var(--ui-color-brand);
   border-radius: var(--ui-radius-button);
   display: block;
   font-weight: 700;
   line-height: 1;
   text-align: center;
   text-decoration: none;
 }
 
 .ui-component-button:hover, .ui-component-button:focus {
   background-color: rgb(0,0,0);
   color:rgb(250, 250, 250);
   text-decoration: none;
 }

 .ui-component-button-primary {
   background-color: var(--ui-color-brand);
   color: var(--ui-color-typography-button);
   text-decoration: none;
 }
 
 .ui-component-button-secondary {
   background-color: var(--ui-color-background-primary);
   color: var(--ui-color-brand);
 }
 
 .ui-component-button-big,
 .ui-component-button-normal { padding: .75rem 1rem .875rem; text-decoration: none; }
 
 .ui-component-button-big { width: 100%; }
 
 .ui-component-button-normal { width: fit-content; }
 
 /*--------------------------------------------------------------
 |
 | 6.3 TOGGLE
 |
 --------------------------------------------------------------*/
 
 input[name="toggle"] { display: none; }
 
 .ui-component-toggle {
   background-color: var(--ui-color-background-tertiary);
   border-radius: var(--ui-radius-button);
   color: var(--ui-color-typography-note);
   font-size: var(--ui-typography-h2);
   font-weight: 700;
   line-height: 1;
   margin: var(--ui-gap-pricing) auto .5rem;
   padding: .25rem;
   width: max-content;
 }
 
 .ui-component-toggle--label {
   border-radius: var(--ui-radius-button);
   cursor: pointer;
   padding: .5rem .625rem;
 }
 
 #ui-component-toggle__monthly:checked ~
 div label[for=ui-component-toggle__monthly],
 #ui-component-toggle__yearly:checked ~
 div label[for=ui-component-toggle__yearly] {
   background-color: var(--ui-color-background-primary);
   color: var(--ui-color-typography-body);
 }
 
 /*--------------------------------------------------------------
 |
 | 6.4 CARD
 |
 --------------------------------------------------------------*/
 
 .ui-component-card {
   border: .0625rem solid var(--ui-color-border);
   border-radius: var(--ui-radius-card);
   overflow: hidden;
   width: 100%;
 }

 /*--------------------------------------------------------------
 |
 | 6.5 LIST
 |
 --------------------------------------------------------------*/
 
 .ui-component-list {
  grid-template-columns: 1fr;
  row-gap: .75rem;
}

.ui-component-list--item {
  background-position: left center;
  background-repeat: no-repeat;
  background-size: 1.125rem;
  list-style: none;
  padding-left: 1.875rem;
}

.ui-component-list--item-check {
  background-image: url(../images/check.svg);
}

.ui-component-list--item-cross {
  background-image: url(../images/cross.svg);
}

  /*--------------------------------------------------------------
|
| 7.6 PRICING
|
--------------------------------------------------------------*/

.ui-section-pricing {
  padding-bottom: 1rem;
  padding-top: 1rem;
  text-align: center;
}

.ui-section-pricing__layout {
  justify-content: initial;
  margin-top: var(--ui-gap-pricing);
  row-gap: var(--ui-gap-card);
}

/* CARD */

.ui-component-card--pricing {
  padding: 1.5rem 1.5rem 1.75rem;
  text-align: left;
}

.ui-component-card--pricing-price {
  line-height: 1;
  margin-bottom: 1.25rem;
  margin-top: 1.25rem;
}

.ui-component-card--pricing-amount {
  font-size: var(--ui-typography-h1);
  font-weight: 700;
}

/* AMOUNT */

.ui-component-card--pricing-amount-1::before { content: "$100"; }

#ui-component-toggle__yearly:checked ~ div
.ui-component-card--pricing-amount-1::before { content: "$1100"; }

.ui-component-card--pricing-amount-2::before { content: "$180"; }

#ui-component-toggle__yearly:checked ~ div
.ui-component-card--pricing-amount-2::before { content: "$1900"; }

.ui-component-card--pricing-amount-3::before { content: "$290"; }

#ui-component-toggle__yearly:checked ~ div
.ui-component-card--pricing-amount-3::before { content: "$2900"; }

/* MESDIA */

.ui-component-card--pricing-mesdia-1::before { content: "día"; }

#ui-component-toggle__yearly:checked ~ div
.ui-component-card--pricing-mesdia-1::before { content: "mes"; }

.ui-component-card--pricing-mesdia-2::before { content: "día"; }

#ui-component-toggle__yearly:checked ~ div
.ui-component-card--pricing-mesdia-2::before { content: "mes"; }

.ui-component-card--pricing-mesdia-3::before { content: "día"; }

#ui-component-toggle__yearly:checked ~ div
.ui-component-card--pricing-mesdia-3::before { content: "mes"; }


/* LIST */

.ui-component-list--pricing {
  margin-bottom: 1.5rem;
  margin-top: 1.25rem;
}


  /*------------------------------------------------------------
  IMAGE
  ------------------------------------------------------------*/

  .ui-image-half-right {
    padding-left: var(--ui-layout-gutter);
  }


@media screen and (min-width: 48rem) {
 
 :root {

   /*----------------------------------------------------------
   |
   | TYPOGRAPHY
   |
   ----------------------------------------------------------*/

   /* FONT SIZE */

   --ui-typography-h1: 2.1875rem;
   --ui-typography-h2: 1.75rem;
   --ui-typography-h4: 1.125rem;
   --ui-typography-p : 1.125rem;
   --ui-typography-s : .875rem;

   /* MARGIN */

   --ui-typography-margin-body: 1.25rem;

   /*----------------------------------------------------------
   |
   | LAYOUT
   |
   ----------------------------------------------------------*/

   --ui-layout-container: 4.25rem;
   --ui-layout-gutter   : 1.5rem;

   /* GAP */

   --ui-gap-header: 1.5rem;
   --ui-gap-card  : 1.5rem;
   --ui-gap-faq   : 2rem;

 }

 /*------------------------------------------------------------
 IMAGE
 ------------------------------------------------------------*/

 .ui-image-half-right {
   padding-left: var(--ui-layout-gutter);
 }

/*------------------------------------------------------------
LAYOUT
------------------------------------------------------------*/

.ui-layout-container,
.ui-layout-column-center {
 margin-left: auto;
 margin-right: auto;
}

.ui-layout-grid-2,
.ui-layout-grid-3 {
 column-gap: var(--ui-layout-gutter);
 grid-template-columns: repeat(2, 1fr);
 justify-items: center;
}

.ui-layout-grid-3 div:nth-of-type(3) {
 left: calc(50% + (var(--ui-layout-gutter) / 2));
 position: relative;
}

.ui-layout-column-4 {
 width: calc((var(--ui-layout-grid) * 4) +
        (var(--ui-layout-gutter) * 3));
}

/*------------------------------------------------------------
COMPONENT
------------------------------------------------------------*/

/*------------------------------------------------------------
|
| LIST
|
------------------------------------------------------------*/

.ui-component-list--item {
 background-size: 1.25rem;
 padding-left: 2rem;
}
/*------------------------------------------------------------
|
| PRICING
|
------------------------------------------------------------*/

.ui-component-card--pricing { padding: 2rem 2rem 2.25rem; }

}

@media screen and (min-width: 64rem) {
/*------------------------------------------------------------
LAYOUT
------------------------------------------------------------*/

.ui-layout-container { width: 60rem; }

.ui-layout-grid-3 { grid-template-columns: repeat(3, 1fr); }

.ui-layout-grid-3 div:nth-of-type(3) { position: static; }
:root {
 /*----------------------------------------------------------
 |
 | LAYOUT
 |
 ----------------------------------------------------------*/

 --ui-layout-container: 0;

}
/*------------------------------------------------------------
LAYOUT
------------------------------------------------------------*/

.ui-layout-container { width: 60rem; }

.ui-layout-grid-3 { grid-template-columns: repeat(3, 1fr); }

.ui-layout-grid-3 div:nth-of-type(3) { position: static; }

/*------------------------------------------------------------
|
| PRICING
|
------------------------------------------------------------*/

.ui-section-pricing {
 padding-bottom: 1.5rem;
 padding-top: 1.5rem;
}

.ui-component-card--pricing { padding: 3rem; }
}

@media screen and (min-width: 75rem) {
:root {

 /*----------------------------------------------------------
 |
 | TYPOGRAPHY
 |
 ----------------------------------------------------------*/

 /* FONT SIZE */

 --ui-typography-h1: 2.75rem;
 --ui-typography-h2: 2.1875rem;
 --ui-typography-h4: 1.4375rem;

 /* MARGIN */

 --ui-typography-margin-heading: 1rem;
 --ui-typography-margin-body   : 1.75rem;

 /*----------------------------------------------------------
 |
 | LAYOUT
 |
 ----------------------------------------------------------*/

 --ui-layout-grid  : 4rem;
 --ui-layout-gutter: 2rem;

 /* GAP */

 --ui-gap-header  : 2rem;
 --ui-gap-customer: 4rem;
 --ui-gap-card    : 2rem;
 --ui-gap-pricing : 3rem;
 
}

/*------------------------------------------------------------
TYPOGRAPHY
------------------------------------------------------------*/

.ui-text-intro { font-size: var(--ui-typography-h4); }

/*------------------------------------------------------------
LAYOUT
------------------------------------------------------------*/

.ui-layout-container { width: 70rem; }

/*------------------------------------------------------------
COMPONENT
------------------------------------------------------------*/

/*------------------------------------------------------------
|
| BUTTON
|
------------------------------------------------------------*/

.ui-component-button-big,
.ui-component-button-normal {
 padding-bottom: 1.125rem;
 padding-top: 1rem;
 }

/*------------------------------------------------------------
SECTION
------------------------------------------------------------*/


/*------------------------------------------------------------
|
| PRICING
|
------------------------------------------------------------*/

.ui-section-pricing {
 padding-bottom: 7.5rem;
 padding-top: 7.5rem;
}

.ui-component-card--pricing { padding: 3rem; }

}
