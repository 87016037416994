/****************************** MAIN STYLES - backgorund,deviders ******************************/

/* Main Container */
img, embed, object, video {
	max-width: 100%
}

/*************************************** SHADOW STYLES ****************************************/
.shadow {
	position: relative;
	-moz-box-shadow: 0 14px 10px -10px rgba(0, 0, 0, 0.4);
	-webkit-box-shadow: 0 14px 10px -10px rgba(0, 0, 0, 0.4);
	box-shadow: 0 14px 10px -10px rgba(0, 0, 0, 0.4);
}
.shadow:before, .shadow:after {
	content: '';
	position: absolute;
	z-index: -1;
	bottom: 15px;
	-moz-box-shadow: 0px 15px 12px rgba(0, 0, 0, 0.6);
	-webkit-box-shadow: 0px 15px 12px rgba(0, 0, 0, 0.6);
	box-shadow: 0px 15px 12px rgba(0, 0, 0, 0.6);
}
.shadow:before {
	right: 10px;
	-moz-transform: rotate(4deg) skewX(4deg);
	-webkit-transform: rotate(4deg) skewX(4deg);
	-o-transform: rotate(4deg) skewX(4deg);
	-ms-transform: rotate(4deg) skewX(4deg);
	transform: rotate(4deg) skewX(4deg);
}
/*************************************** SHADOW STYLES ****************************************/
.shadow1 {
	position: relative;
	-moz-box-shadow: 0 11px 11px -9px rgba(0, 0, 0, 0.2);
	-webkit-box-shadow: 0 11px 11px -9px rgba(0, 0, 0, 0.2);
	box-shadow: 0 11px 11px -9px rgba(0, 0, 0, 0.2);
}
.shadow1:before, .shadow1:after {
	content: '';
	position: absolute;
	z-index: -1;
	bottom: 15px;
	-moz-box-shadow: 0px 15px 12px rgba(0, 0, 0, 0.2);
	-webkit-box-shadow: 0px 15px 12px rgba(0, 0, 0, 0.2);
	box-shadow: 0px 15px 12px rgba(0, 0, 0, 0.2);
}
.shadow1:before {
	right: 10px;
	-moz-transform: rotate(4deg) skewX(4deg);
	-webkit-transform: rotate(4deg) skewX(4deg);
	-o-transform: rotate(4deg) skewX(4deg);
	-ms-transform: rotate(4deg) skewX(4deg);
	transform: rotate(4deg) skewX(4deg);
}

/****************************** Latest Photos ******************************/
.latest figure {
	margin-bottom:30px;
}
.latest figure .thumb {
	display: block;
}
.latest figure .thumb img {
	border-bottom:1px dashed #999999;
}
.latest figure {
	background:#cecece url(../images/rausch.png) repeat;
	border-top:4px solid #747474;
	-webkit-border-bottom-right-radius: 5px;
	-webkit-border-bottom-left-radius: 5px;
	-moz-border-radius-bottomright: 5px;
	-moz-border-radius-bottomleft: 5px;
	border-bottom-right-radius: 5px;
	border-bottom-left-radius: 5px;
}
.latest figure figcaption {
	margin: 0 20px;
	text-align: center;
	padding: 0 0 10px 0;
}
.latest figure figcaption .heading {
	margin-bottom: 10px;
	font-size: 24px;
	text-transform: uppercase;
	display: block;
}
/****************************** News & FAQ ******************************/
.video-holder {
	float:left;
	width:100%;
	height:100%;
}
.video-container {
	position: relative;
	padding-bottom: 50%;
	height: 0;
	overflow: hidden;
	margin-bottom:5px;
	border:3px solid #f9efe3;
}
.video-container iframe, .video-container object, .video-container embed {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height:100%;
}
/* ACCORDION -------------------------------------------------*/
.accordion-trigger {
	text-decoration: none;
	padding: 3px 0;
	background:url(../images/toggle.png) no-repeat right 5px;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
	cursor:pointer;
}
.accordion-trigger h3 {
	text-transform:none;
	font-size:18px;
	font-family:Arial, Helvetica, sans-serif;
}
.accordion-trigger.active {
	background:url(../images/toggle.png) no-repeat right -80px;
}
.accordion-trigger:hover {
	background:url(../images/toggle.png) no-repeat right 5px;
}
.accordion-trigger.active:hover {
	background:url(../images/toggle.png) no-repeat right -80px;
}
.accordion-container {
	margin-bottom: 0px;
	padding:0px 0px 20px 0;
}
.accordion-container p {
	color: #543d1f;
}
.accordion-container a.buttonhome {
	margin-top:-10px;
	margin-bottom:10px;
}
p.quote {
	font-size:12px;
	font-style:italic;
	color: #543d1f;
}
.latest h3 {
	margin-bottom:20px;
}

 @media screen and (max-width: 720px) {
 .ia-container figure {
 left: 40px;
 width: 260px;
}
 .ia-container input {
 width: 40px;
}
 .ia-container input:checked ~ figure {
 left: 260px;
}
 .ia-container figcaption span {
 font-size: 16px;
}
}
 @media screen and (max-width: 520px) {
 .ia-container figure {
 left: 20px;
 width: 180px;
}
 .ia-container input {
 width: 20px;
}
 .ia-container input:checked ~ figure {
 left: 180px;
}
 .ia-container figcaption span {
 font-size: 12px;
 letter-spacing: 2px;
 padding: 10px;
 margin-top: -20px;
}
}
.gallery h4 {
	font-style:italic;
	font-size:13px;
}
p.gallery {
	margin-top:25px;
}
/****************************** BLOG - Blog Page ******************************/
.post {
	margin-bottom:40px;
}
.post a {
	font-size:12px;
	text-transform:uppercase;
	font-weight:bold;
}
.post img {
	margin-bottom:15px;
}
.entry-date {
	position: absolute;
	left: -20px;
	margin-top:-100px;
	background:#f9efe3 url(../images/rausch.png) repeat;
	width:70px;
	height:70px;
	color: #543d1f;
	font-size:14px;
	line-height:70px;
	text-align:center;
	border-radius:35px;
	-moz-border-radius: 35px;
	-webkit-border-radius: 35px;
	box-shadow: 2px 2px 0 0 rgba(0, 0, 0, 0.1);
}
/****************************** Blog - Sidebar ******************************/
aside {
	background:#f9efe3 url(../images/rausch.png) repeat;
	padding:0 20px 20px 20px;
}
h4.sidebarheader {
	margin-top:20px;
	margin-bottom:15px;
	text-transform:uppercase;
}
.recentnews {
	margin-bottom:30px;
}
/* #Tabs (activate in tabs.js)
================================================== */
ul.tabs {
	display: block;
	margin: 0 0 4px 0;
}
ul.tabs li {
	width: auto;
	height: 18px;
	padding:0;
	float: left;
	margin:0 5px 0 0;
}
ul.tabs li a {
	text-decoration: none;
	width: auto;
	padding:5px;
	margin:0;
	color: #543d1f;
	background:url(../images/bg/lightpaperfibers.png) repeat;
	-webkit-border-top-left-radius: 5px;
	-webkit-border-top-right-radius: 5px;
	-moz-border-radius-topleft: 5px;
	-moz-border-radius-topright: 5px;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
}
ul.tabs li a.active {
	background:#543d1f;
	position: relative;
	color: #fff;
}
ul.tabs li:first-child a.active {
	margin-left: 0;
}
ul.tabs-content {
	display: block;
	background:#e2d3c1 url(../images/rausch.png) repeat;
	padding:20px 10px 5px 10px;
	-webkit-border-bottom-right-radius: 5px;
	-webkit-border-bottom-left-radius: 5px;
	-moz-border-radius-bottomright: 5px;
	-moz-border-radius-bottomleft: 5px;
	border-bottom-right-radius: 5px;
	border-bottom-left-radius: 5px;
}
ul.tabs-content > li {
	display:none;
}
ul.tabs-content > li.active {
	display: block;
}
ul.tabs:before, ul.tabs:after {
	content: '\0020';
	display: block;
	overflow: hidden;
	visibility: hidden;
	width: 0;
	height: 0;
}
ul.tabs:after {
	clear: both;
}
ul.tabs {
	zoom: 1;
}
.latestphotos {
	margin-bottom:10px;
}
ul.categories li {
	display:inline-block;
}
ul.categories li a {
	display:inline-block;
	background:#e2d3c1 url(../images/rausch.png) repeat;
	padding:5px;
	color:#543d1f;
}
/******************************* FEATURES / Resume  *******************************/
.resume .one_third {
	border-top:5px solid #f9efe3;
}
.resume .two_third {
	background:#fff;
}
.resume .two_third p {
	padding:10px;
}
.resume .two_third h4 {
	padding:10px;
}
.resume .two_third ul {
	padding:10px;
	margin-top:-20px;
}
.resume .two_third ul li {
	color:#726072;
}
.resume img {
	margin-bottom:-30px;
}
/******************************* CONTACT FORM  *******************************/
form#contact_form input[type="text"] {
	border: 1px solid #e2d3c1;
	background-color: white;
	height: 30px;
	padding: 0 10px;
	width: 250px;
	margin-bottom:20px;
}
form#contact_form input#email {
	border: 1px solid #e2d3c1;
	background-color: white;
	height: 30px;
	padding: 0 10px;
	width: 250px;
	margin-bottom:20px;
}
form#contact_form textarea {
	border: 1px solid #e2d3c1;
	background-color: white;
	width: 500px;
	margin-bottom:20px;
}
form#contact_form label {
	margin-right: 20px;
	font-size: 12px;
	font-weight:bold;
	text-transform: uppercase;
}
form#contact_form input[type="text"]:focus, form#contact_form input[type="text"]:hover, form#contact_form textarea:focus, form#contact_form textarea:hover, form#contact_form input[type="text"]:focus, form#contact_form input[type="text"]:hover, form#contact_form textarea:focus, form#contact_form textarea:hover {
	background-color:#fff3e4;
}
form#contact_form input[type="submit"] {
	background:#f9efe3 url(../images/rausch.png) repeat;
	border:none;
	padding:5px;
	font-size:11px;
	text-transform:uppercase;
	margin-bottom:10px;
}
.map-container {
	margin-top:-40px;
	position: relative;
	padding-bottom:20%;
	margin-bottom:40px;
	overflow: hidden;
}
.map-container iframe, .map-container object, .map-container embed {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height:100%;
}
.contact .first {
	background:#f9efe3 url(../images/rausch.png) repeat;
	padding:10px;
	margin-bottom:40px;
}
.contact ul li {
	font-size:14px;
}
/****************************** Shortcodes ******************************/
/* Content Shortcodes */
.one_half {
	width: 46%;
}
.one_third {
	width: 30.66%;
}
.two_third {
	width: 65.33%;
}
.one_fourth {
	width: 22%;
}
.one_fifth {
	width: 16.8%;
}
.one_sixth {
	width: 15%;
}
.one_half, .one_third, .two_third, .one_fourth, .one_fifth {
	margin-right: 4%;
	margin-bottom: 10px;
	float: left;
}
.one_sixth {
	margin-right: 2%;
	margin-bottom:20px;
	float: left;
}
.lastcolumn {
	margin-right: 0!important;
	clear: right;
}
/* #Page Styles
================================================== */
.content {
	padding-top: 20px;
}
.content.right {
	float: right;
}

/* #Media Queries
================================================== */

	/* Smaller than standard 960 (devices and browsers) */
	@media only screen and (max-width: 1300px) {
.caption {
  width:30%;
}
}
@media only screen and (max-width: 1100px) {
.caption {
  display:none;
}
}

	/* Tablet Portrait size to standard 960 (devices and browsers) */
	@media only screen and (min-width: 768px) and (max-width: 959px) {
.caption {
	display:none;
}
form#contact_form textarea {
 width: 350px;
}
}

	/* All Mobile Sizes (devices and browser) */
@media only screen and (max-width: 767px) {
.mainmenu select {
 position:absolute;
 top:0;
 right:0;
 margin-top:40px;
}
.mainmenu {
 height:10px;
}
.one_half, .one_third, .two_third, .one_fourth, .one_fifth, .one_sixth {
width: 100%;
}
.caption {
 display:none;
}
.info h1 {
 line-height:40px;
 font-size:36px;
}
form#contact_form textarea {
 width: 300px;
}
}

	/* Mobile Landscape Size to Tablet Portrait (devices and browsers) */
	@media only screen and (min-width: 480px) and (max-width: 767px) {
.mainmenu select {
 position:absolute;
 top:0;
 right:0;
 margin-top:40px;
}
.mainmenu {
 height:10px;
}
 ul.tabs {
 margin-top:20px;
}
.caption {
 display:none;
}
.info h1 {
 line-height:40px;
 font-size:36px;
}
form#contact_form textarea {
 width: 300px;
}
}

	/* Mobile Portrait Size to Mobile Landscape Size (devices and browsers) */
	@media only screen and (max-width: 479px) {
.mainmenu select {
 position:absolute;
 top:0;
 right:0;
 margin-top:40px;
 width:40%;
}
.mainmenu {
 height:10px;
}
.caption {
 display:none;
}
ul.tabs {
 margin-top:20px;
}
ul.tabs li {
 padding: 0 5px 0 0px;
}
 .mainmenu {
float: left;
width: 100%;
margin-top:-200px;
}
.info h1 {
 line-height:40px;
 font-size:36px;
}
form#contact_form textarea {
 width: 200px;
}
form#contact_form input[type="text"] {
 width: 150px;
}
form#contact_form input#email {
 width: 150px;
}
}
/* Responsive Menu
================================================== */
	
#mainmenu {
	visibility: visible;
}
#responsive-menu {
	display: none;
	float: left;
}
 @media only screen and (max-width: 767px) {
 #mainmenu {
visibility: hidden;
height: 0;
}
 #responsive-menu {
display: inline-block;
width: 100%;
margin-top: 13px;
}
}
